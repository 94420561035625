<template>
  <div>
    <!-- <div class="nav-top">
      <div class="wp">
        <div class="nav-tips">
          <div class="mr-2 pointer"><i class="mr-1 iconfont icon-weixin"> </i><span>官方微信</span></div>
          <div class="mr-2 pointer" @click="addBookmark"><i class="mr-1 iconfont icon-shoucang-"> </i><span>收藏本站</span>
          </div>
          <div class="mr-2">
            <span><a href="/intro" style="color: #fff;"><i class="mr-1 iconfont icon-phone"> </i>联系我们</a></span>
          </div>
        </div>
      </div>

    </div>

 -->

  </div>
</template>

<script>
  export default {
    name: "header-1",
    methods: {
      addBookmark() {
        var url = window.document.location.href;
        var title = "人事人才测评服务专栏"
        try {
          window.external.addFavorite(url, title);
        } catch (e) {
          try {
            window.sidebar.addPanel(title, url, "");
          } catch (e) {
            alert("抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请使用Ctrl+D进行添加");
          }
        }

      },

      // 判断浏览器
      checkBrowserr() {

        // var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
        // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
        var isIE = this.isIE() //判断是否IE<11浏览器
        if (isIE) {
          this.$message({
            message: '为了更好的使用本系统，我们推荐您使用谷歌Chrome浏览器,门户网站页面在IE浏览器中仅支持10及以上版本！',
            showClose: true,
            type:"warning"
          });
        }
      },
      isIE() { //ie
        if (!!window.ActiveXObject || "ActiveXObject" in window)
          return true;
        else
          return false;
      },

    },
    mounted() {
      this.checkBrowserr()
    }
  }
</script>

<style>
</style>
