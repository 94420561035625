<template>
  <div style="padding-top: 60px">
    <div
      style="width: 1200px; min-width: 1200px; height: 88px; margin: 0px auto"
    >
      <div class="logo-class" :style="{ backgroundImage: 'url(' + img + ')' }">
        <div
          style="
            display: inline-block;
            margin-left: 720px;
            margin-top: 20px;
            font-size: 1.4rem;
            color: #00448f;
            text-shadow: 2px 2px 2px #fff, -2px -2px 2px #fff;
            font-weight: bold;
          "
        >
          人事人才测评服务专栏
        </div>
      </div>
      <div class="search">
        <input
          class="search-input"
          type="text"
          id="txt_SearchAll"
          name="txt_SearchAll"
          placeholder="请输入你的关键字"
          value=""
          v-model="keyword"
        />
        <button type="button" class="btn search-btn" @click="serchNews">
          <i class="iconfont icon-mb-search mr-1"></i>
          搜索
        </button>
      </div>
    </div>
    <div class="navBar">
      <ul class="navBar-ul">
        <a
          href="javascript:;"
          v-for="(item, index) in navList"
          :key="index"
          @click="changeClass(item, index)"
          :class="{
            active: activeIndex == index
          }"
        >
          <li>{{ item.name }}</li>
        </a>
        <a href="http://rsks.class.com.cn" target="_blank">
          <li>网上购书</li>
        </a>
      </ul>
      <div class="navBar-right d-flex">
        <!-- <div class="navBar-right-box navBar-border-right">
          <img src="@/assets/images/index/icon-bag.png">
          <div class="mt-2"> 网上商城 </div>
        </div> -->
        <div class="navBar-right-box">
          <img src="@/assets/images/index/icon-person.png" />
          <div
            class=""
            @click="
              showModal = true
              registerFlag = false
            "
          >
            登录/注册
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showModal"
      title="温馨提示"
      hide-footer
      centered
      class="custom-width-modal"
    >
      <div>
        <h3 v-if="!registerFlag">
          关于四川省人力资源和社会保障厅-人事人才测评服务专栏-个人后台接入四川人社在线公共服务平台的提示
        </h3>
        <div class="my-4" v-if="!registerFlag">
          <p>各位考生：</p>

          <p style="text-indent: 2em">
            四川人事考试基地网用户认证模块将于2024年7月19并入四川人社在线公共服务平台。升级完成后，四川人事考试基地网将正式更名为：四川省人力资源和社会保障厅（
            <a href="https://rst.sc.gov.cn" target="_blank">rst.sc.gov.cn</a>
            ）“人事人才测评服务”专栏（
            <a href="https://www.jdpta.com" target="_blank">
              https://www.jdpta.com
            </a>
            ）。
          </p>
          <p style="text-indent: 2em">
            届时，点击“登录/注册”按钮，您将自动跳转至四川人社在线公共服务平台。已在该服务平台注册的考生可继续使用原账号登录，未注册的考生请先下载四川人社APP完成注册，再进行登录。
          </p>
          <p style="text-indent: 2em">
            登录和注册问题请查看四川人社APP登录文档，登录成功后相关报名、准考证打印、成绩查询等业务问题请咨询四川省人事人才考试测评基地：028-86740101，或网站“在线咨询”转“人工客服”。
          </p>
          <p style="text-indent: 2em">
            详细通知内容及附件：
            <a
              href="https://www.jdpta.com/newsDetails?newsId=E3598FAEFF8CCB0C"
              target="_blank"
            >
              https://www.jdpta.com/newsDetails?newsId=E3598FAEFF8CCB0C
            </a>
          </p>
        </div>
        <div
          class="my-4"
          style="height: calc(100vh - 200px); overflow-y: auto"
          v-else
        >
          <div>
            <h3 style="text-align: center">人事人才测评服务用户隐私政策</h3>
            <p>
              &emsp;&emsp;尊敬的“人事人才测评服务”专栏用户（以下统称“您”），为了便于您使用“人事人才测评服务”专栏（以下简称“本专栏”），本专栏会按照《“人事人才测评服务”专栏用户隐私政策》（以下简称“本政策”）来约定，收集、使用、存储和对外提供您的个人信息。本政策适用于您通过任何方式对本专栏的访问和使用。
              <br />
              &emsp;&emsp;您在使用本专栏的产品或服务时，本专栏需要按照《中华人民共和国民法典》、《中华人民共和国网络安全法》等国家法律法规的相关要求收集、存储、使用、共享您的信息，以及向您提供服务及提升服务质量，本专栏希望通过本政策向您说明：在您使用本专栏的产品或服务时，本专栏会收集哪些数据、为什么收集这些数据、会利用这些数据做什么以及本专栏如何保护这些数据。本政策与您所使用的本专栏的产品或服务息息相关，对于您行使用户权利及保护您的用户信息至关重要，请您在使用本专栏的产品或服务前认真阅读并充分理解本政策所写明的内容。
              <br />
              &emsp;&emsp;请您仔细阅读本政策，并确定理解本专栏对您的信息的处理规则。如您点击“我已阅读并同意”并注册、使用本专栏，即视为您同意本政策(含更新版本)内容，并且同意本专栏按照本政策收集、使用、保存和对外提供您的用户信息。
              <br />
              &emsp;&emsp;本政策适用于使用本专栏的所有用户，如您在本专栏更新本政策后继续使用本专栏的产品或服务，即意味着您同意本政策（含更新版本）内容，并同意本专栏按照本政策（含更新版本）收集、使用、保存和对外提供您的相关信息。
              <br />
              &emsp;&emsp;请您注意本政策中加粗部分的个人信息均为您的敏感信息，加粗部分系统权限为敏感权限，请您在提供以上信息或对以上权限进行授权前谨慎考虑。
              <br />
              【如何收集与使用您的个人信息】
              <br />
              （一）责任说明
              <br />
              &emsp;&emsp;本专栏负责收集、管理及维护用户真实信息，以及您在本专栏产生的行为信息，根据业务需求收集您的业务必填信息。本专栏将按现有技术提供相应的安全措施来保护信息不丢失、泄漏，不被滥用和变造。如个人信息或业务信息发生泄漏，与本专栏所有方无关。
              <br />
              （二）信息的收集
              <br />
              &emsp;&emsp;为保障您使用本专栏过程的安全性，改善您的使用体验，本专栏会收集、使用和保存与您有关的个人信息。您需要向本专栏授权获取或您按页面提示文字主动输入全部或部分信息，并同意本专栏将您向本专栏提供的信息与法律法规允许的机构或政府机关授权的机构进行对比校验。如果您不提供相关信息，您将无法享受本专栏提供的以下对应服务：
              <br />
              &emsp;&emsp;1.
              <br />
              &emsp;&emsp;当您注册本专栏时，您需要向四川人社在线公共服务平台主动输入您的姓名、密码、证件类型、证件号码、手机号、邮箱等信息。如您不提供上述信息，本专栏将无法向您提供考试报名、资讯发布、缴费、准考证下载及打印、考试成绩查询以及其他需登录才可使用的服务。
              <br />
              &emsp;&emsp;2.当您重置四川人社在线公共服务平台登录密码时，您需要向四川人社在线公共服务平台授权获取或主动输入您的姓名、手机号码、短信验证码信息，同时同意平台对您提供的有关信息进行有效性核验。
              <br />
              &emsp;&emsp;3.当您使用考试报名服务时，本专栏会收集您的民族、政治面貌、专业技术职务、职称、职业资格、工作单位有关信息、工作经历、居住地、主要社会关系等信息，如果您不使用有关服务，则无需提供相关信息。
              <br />
              （三）信息的使用
              <br />
              &emsp;&emsp;在本专栏向您提供有关服务期间，您同意授权本专栏持续收集您的信息。在符合相关法律法规的前提下，本专栏可能将收集的信息用作以下用途：
              <br />
              &emsp;&emsp;1.向您提供报名服务。
              <br />
              &emsp;&emsp;2.满足您的个性化需求。例如：个性化的帮助服务和指引，或对您和其他用户做出其他方面的回应。
              <br />
              &emsp;&emsp;3.服务优化和开发。例如，本专栏会根据本专栏响应您的需求时产生的信息，优化本专栏的服务，但这些信息不会包含您的任何身份识别信息。
              <br />
              &emsp;&emsp;4.保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本专栏相关协议、规则的情况，本专栏可能使用您的账号信息、并整合设备信息、有关网络日志以及有关政府机构、合作伙伴分享的信息，来判断您账号风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。例如，本专栏会将您的信息用于身份验证、安全防范、投诉处理、纠纷协调、诈骗监测等用途。
              <br />
              &emsp;&emsp;5.向您提供与您更加相关的服务。例如，向您提供您可能感兴趣的类似功能或服务等。
              <br />
              &emsp;&emsp;6.您授权同意的以及法律法规规定的其它用途。
              <br />
              二、【本专栏如何使用Cookie和同类技术】
              <br />
              &emsp;&emsp;本专栏可能会通过Cookies（储存在用户本地终端上的数据）和其他相关技术收集和使用您的信息。本专栏使用Cookies的具体用途包括：
              <br />
              &emsp;&emsp;1.记住您的身份。例如：Cookies有助于本专栏辨认您作为本专栏的注册用户的身份。
              <br />
              &emsp;&emsp;2.分析您使用本专栏服务的情况，以便为您提供更加周到的个性化服务。
              <br />
              &emsp;&emsp;3.您可以通过浏览器等设置拒绝或管理Cookies。但请注意，如果停用Cookies，您有可能无法享受最佳的服务体验，某些功能可能会受到影响。
              <br />
              三、【本专栏如何对外提供您的个人信息】 （一）共享
              <br />
              &emsp;&emsp;本专栏承诺将对您的信息进行严格保密。除法律、法规及监管部门另有规定外，本专栏不会与本专栏以外的公司、组织和个人共享您的个人信息，以下情况除外：
              <br />
              &emsp;&emsp;1.在获取明确同意的情况下共享：获得您的明确同意后，本专栏会与其他方共享您的个人信息。
              <br />
              &emsp;&emsp;2.在法定情形下的共享：本专栏可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
              <br />
              &emsp;&emsp;3.为了促成办理服务或协助解决争议，某些情况下只有共享您的个人信息，才能促成办理或处理您与他人的纠纷或争议。
              <br />
              &emsp;&emsp;4.国家政务数据共享：为贯彻落实国家政务数据共享要求，提高部门联办效率，向有关部门共享您的个人信息。
              <br />
              &emsp;&emsp;5.与本专栏合作的第三方服务方共享：为便于本专栏向您提供服务，您的个人信息可能会在本专栏与本专栏合作的第三方服务方共享。本专栏只会共享姓名、证件类型、证件号码及其他使用本专栏合作的第三方服务方的产品或服务所必要的个人信息，如果本专栏共享您的除上述必要个人信息以外的其他个人信息，或本专栏合作的第三方服务方改变个人信息的使用及处理目的，本专栏将再次征求您的明示同意。
              <br />
              &emsp;&emsp;6.本专栏可能会向移动运营商提供您的部分账号信息，以便及时向您的移动设备发送消息或服务通知。
              <br />
              （二）转让
              <br />
              &emsp;&emsp;本专栏不会将您的个人信息转让给任何公司、组织机构和个人。但以下情况除外：
              <br />
              &emsp;&emsp;1.事先获得您的授权或同意。
              <br />
              &emsp;&emsp;2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供。
              <br />
              （三）公开披露
              <br />
              &emsp;&emsp;本专栏仅会在以下情况下，公开披露您的个人信息：
              <br />
              &emsp;&emsp;1.获得您明确同意或基于您的主动选择；
              <br />
              &emsp;&emsp;2.如果本专栏确定您出现违反法律法规或严重违反本专栏相关协议规则的情况，或为保护本专栏或公众的人身财产安全免遭侵害，本专栏可能依据法律法规，遵守法院判决、裁定或其他法律程序的规定，遵守相关政府机关或其他有权机关的要求或本专栏相关协议规则公开披露您的个人信息，包括相关违规行为以及本专栏已对您采取的措施。
              <br />
              &emsp;&emsp;3.根据国家政策安排或法律规定，本专栏以及有关政府机构有可能进行组织架构调整、资产调配、转让或类似的交易，您的信息有可能作为其中一部分而被转移。本专栏将遵守相关法律法规的要求，在转移前通知您，确保信息在转移时的机密性，以及变更后继续履行相应责任和义务。
              <br />
              （四）共享、转让、公开披露个人信息时事先征得授权同意的例外。
              <br />
              &emsp;&emsp;以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
              <br />
              &emsp;&emsp;1.与国家安全、国防安全有关的；
              <br />
              &emsp;&emsp;2.与公共安全、公共卫生、重大公共利益有关的；
              <br />
              &emsp;&emsp;3.与犯罪侦查、起诉、审判和判决执行等有关的；
              <br />
              &emsp;&emsp;4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
              <br />
              &emsp;&emsp;5.您自行向社会公众公开的个人信息；
              <br />
              &emsp;&emsp;6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
              <br />
              &emsp;&emsp;7.与本专栏履行法律法规规定以及行业主管部门规定的义务相关的其他情形。
              <br />
              &emsp;&emsp;8.符合其他国家要求的。
              <br />
              四、【本专栏如何保存您的个人信息】
              <br />
              &emsp;&emsp;本专栏在中华人民共和国境内收集和产生的用户信息将存储在中华人民共和国境内。本专栏仅在本政策所述目的所必需期和法律法规规定的时限内保存您的用户信息，超出保存期限后，本专栏将对所收集的信息进行删除处理。
              <br />
              &emsp;&emsp;您在使用本专栏服务期间，本专栏将持续为您保存您的用户信息。在您删除本专栏账号后，本专栏仅在法律法规规定的期限内，以及为实现本政策声明的目的所必须的时限内保留您的用户信息。超出上述用户信息保存期限后，本专栏会对您的用户信息进行删除处理。
              <br />
              &emsp;&emsp;在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：
              <br />
              &emsp;&emsp;1.遵守适用的法律法规等有关规定。
              <br />
              &emsp;&emsp;2.遵守法院判决、裁定或其他法律程序的要求。
              <br />
              &emsp;&emsp;3.遵守相关政府机关或其他有权机关的要求。
              <br />
              &emsp;&emsp;4.我们有理由确信需遵守法律法规等有关规定。
              <br />
              &emsp;&emsp;5.为执行相关服务协议或本隐私政策、维护社会公共利益、处理投诉或纠纷，保护我们的客户或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
              <br />
              五、【您如何管理您的用户信息】 （一）查看及修改您的用户信息
              <br />
              &emsp;&emsp;您登录本专栏后，可以在“我的后台-个人资料”中，进行维护个人基本信息、照片信息等资料。
              <br />
              （二）改变您授权同意的范围或撤回您的授权
              <br />
              &emsp;&emsp;您可以通过向我们申请注销账号的方式撤回全部隐私授权，本专栏将不会再收集、使用或对外提供与该账号相关的个人信息，但您在使用本专栏服务期间提供或产生的信息，本专栏仍需按照相关要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。
              <br />
              &emsp;&emsp;当您撤回同意或授权后，本专栏无法继续为您提供撤回同意或授权所对应的便民服务。但您撤回同意或授权的决定，不会影响此前本专栏基于您的授权而开展的使用、存储和对外提供您用户信息的处理。
              <br />
              （三）注销用户管理
              <br />
              &emsp;&emsp;注销用户即您可以向报名地考试机构申请删除账号信息。在删除账号信息之后，本专栏将停止为您提供服务，并依据您的要求删除您的账号信息，法律法规另有规定的除外。
              <br />
              &emsp;&emsp;在以下情形中，您可以向本专栏提出删除账号信息的请求：
              <br />
              &emsp;&emsp;1.如果本专栏处理用户信息的行为违反法律法规；
              <br />
              &emsp;&emsp;2.如果本专栏收集、使用您的用账号信息，却未征得您的同意；
              <br />
              &emsp;&emsp;3.如果本专栏处理账号信息的行为违反了与您的约定；
              <br />
              &emsp;&emsp;4.如果您不再使用本专栏的产品或服务；
              <br />
              &emsp;&emsp;5.如果本专栏不再为您提供产品或服务。
              <br />
              &emsp;&emsp;当您从本专栏的服务中删除信息后，本专栏可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
              <br />
              （四）在访问、修改、删除、注销账号的相关信息时，本专栏可能会要求您进行身份验证并审核合法性，以保障账号安全。请您理解，由于技术所限、基于法律法规要求，您的某些请求可能无法进行响应。基于法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除。
              <br />
              （五）响应您的上述请求
              <br />
              &emsp;&emsp;为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。本专栏可能会先要求您验证自己的身份，然后再处理您的请求。请您理解，对于某些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，本专栏可能会予以拒绝。
              <br />
              &emsp;&emsp;尽管有上述约定，但按照法律法规要求，如涉及以下情形本专栏将可能无法响应您的请求：
              <br />
              &emsp;&emsp;1.与国家安全、国防安全相关的；
              <br />
              &emsp;&emsp;2.与公共安全、公共卫生、重大公共利益相关的；
              <br />
              &emsp;&emsp;3.与犯罪侦查、起诉、审判和判决执行等相关的；
              <br />
              &emsp;&emsp;4.有充分证据表明您存在主观恶意或滥用权利的；
              <br />
              &emsp;&emsp;5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
              <br />
              &emsp;&emsp;6.涉及商业秘密的； 六、【征得授权同意的例外】
              <br />
              &emsp;&emsp;根据相关法律法规规定，以下情形中，本专栏可能会收集、使用您的相关用户信息而无需另行征求您的授权同意：
              <br />
              &emsp;&emsp;1.与国家安全、国防安全直接相关的；
              <br />
              &emsp;&emsp;2.与公共安全、公共卫生、重大公共利益直接相关的；
              <br />
              &emsp;&emsp;3.与犯罪侦查、起诉、审判和判决执行等直接相关的；
              <br />
              &emsp;&emsp;4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
              <br />
              &emsp;&emsp;5.收集的用户信息是您自行向社会公众公开的；
              <br />
              &emsp;&emsp;6.合法公开披露的信息中收集用户信息的，如合法的新闻报道、政府信息公开等渠道；
              <br />
              &emsp;&emsp;7.根据您要求签订和履行合同所必需的；
              <br />
              &emsp;&emsp;8.对于维护本专栏所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障、欺诈或被盗用等；
              <br />
              &emsp;&emsp;9.法律法规规定及行业主管部门有关规定的义务相关的；
              <br />
              &emsp;&emsp;10.本专栏或接入的第三方服务为政府机构或具有法律赋予的行政权力机构，因执行公权利而进行收集、使用用户数据的行为。
              <br />
              七、【本专栏如何保护您的用户信息】
              <br />
              本专栏将努力为用户信息安全提供保障，以防止信息的丢失、不当使用、未经授权访问或公开披露。
              <br />
              &emsp;&emsp;（一）为了保障您的信息安全，本专栏将在法律法规相关规定要求和现有技术水平下使用符合业界标准的安全保护措施保护您提供的用户信息，采取物理、技术和行政管理安全措施降低丢失、误用、非授权访问、披露和更改的风险，尽力防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。
              <br />
              &emsp;&emsp;（二）本专栏建立专门的管理制度、流程和组织以保障信息的安全，严格限制访问信息的人员范围，要求他们遵守保密义务。
              <br />
              &emsp;&emsp;（三）如果本专栏提供的全部或部分服务停止使用，本专栏将通过公告、消息推送通知、弹框等形式向您进行告知，同时停止相关服务对您用户信息的收集等操作，保护您的用户信息安全。如因技术故障、网络攻击、自然灾害及事故、人为因素等各种原因造成全部或部分政务服务、便民服务无法使用，本专栏将采取应急处置和恢复措施予以应对，尽快恢复服务。
              <br />
              &emsp;&emsp;（四）互联网环境并非百分之百安全，本专栏将尽力确保您的用户信息安全性。如果本专栏的物理、技术、或管理防护设施遭到破坏，导致发生信息被非授权访问、公开披露、篡改、或毁坏及其它个人信息泄露安全事件，本专栏会启动应急预案，阻止安全事件扩大，并以消息通知等形式将相关情况告知您。同时，本专栏还将按照相关要求，上报个人信息安全事件的处置情况。
              <br />
              &emsp;&emsp;（五）您在使用本专栏服务时，本专栏会通过您的手机号码、姓名、身份证号或者其他身份验证信息来识别您的身份，请您务必妥善保管上述信息。一旦您泄露了上述信息，您的账号信息可能因此泄露，并可能使您的资金安全等个人权益遭受损失。
              <br />
              &emsp;&emsp;（六）本专栏会采取一切合理可行的措施，确保未收集与您所办理业务无关的个人信息。
              <br />
              八、【本专栏如何保护未成年人信息】
              <br />
              &emsp;&emsp;1.本专栏期望父母或监护人指导未成年人使用本专栏的服务。本专栏将根据国家相关法律法规的规定保护未成年人的信息的保密性及安全性。
              <br />
              &emsp;&emsp;2.如您为未成年人，请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用本专栏的服务或向本专栏提供信息。如您的监护人不同意您按照本政策使用本专栏的服务或向本专栏提供信息，请您立即终止使用本专栏的服务并及时通知本专栏，以便本专栏采取相应的措施。
              <br />
              &emsp;&emsp;3.对于经父母或法定监护人同意而收集未成年人用户信息的情况，本专栏只会在受到法律允许、父母或法定监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。如果父母或监护人发现本专栏在未获父母或监护人同意的情况下收集了未成年人的用户信息，请通过官方渠道反馈联系本专栏，本专栏会设法尽快删除相关数据。
              <br />
              &emsp;&emsp;4.未成年人的实名认证信息受实名认证管理机构管理，基于管理机构风险控制的考虑，未成年人的实名认证可能存在无法通过的情况，在此情况下，本专栏将无法提供相应服务。
              <br />
              九、【您的用户信息如何储存及转移】
              <br />
              &emsp;&emsp;本专栏在中华人民共和国境内收集的用户信息，将存储在中华人民共和国境内。本专栏仅在本政策所述目的所必需期间和法律法规规定的时限内保留您的用户信息。如果需要将您的用户信息转移到境外，本专栏将：
              <br />
              &emsp;&emsp;1.另行获得您的授权同意，并履行法定程序，在符合届时适用法律的情形下使您的用户信息得到足够的保护；
              <br />
              &emsp;&emsp;2.该等转移将在符合届时适用法律要求的前提下进行，即便获得您的授权但是转移方式或者目的不符合相关法律法规规定的，本专栏也不会进行转移。
              <br />
              十、【本政策如何更新】
              <br />
              &emsp;&emsp;在相关法律法规发生变化或本专栏服务发生变动等必要情形下，本专栏如需对本政策做出修改，将在修订生效前与请您确认，确认后即生效，并取代此前相关内容。您如不同意新的修改内容，请及时与本专栏联系或立即停止使用本政策约定的服务；如您选择同意并继续使用相关服务，则视为您完全同意并接受新的修改内容。如客观情况及运营方针变化导致本专栏需要中断或停止相关服务的，本专栏将及时进行通知，请您对此表示理解和认同。
              <br />
              十一、【如何联系本专栏】
              <br />
              &emsp;&emsp;如您对本政策存在任何疑问，或有任何相关的投诉、意见，请通过本专栏官方咨询渠道：（点击专栏首页“在线咨询”）联系在线客服进行反馈。
              <br />
              十二、【其它】
              <br />
              &emsp;&emsp;（一）本政策的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律、行政法规和规范性文件。
              <br />
              &emsp;&emsp;（二）本政策无论因何种原因部分无效或不可执行，都不影响本政策其它部分。
              <br />
              &emsp;&emsp;（三）本政策中未尽事宜，按照法律法规和本专栏相关业务规则及国内通行的惯例办理。
              <br />
              &emsp;&emsp;（四）因本政策内容或执行发生任何争议，当事各方应协商解决；协商不成时，应向本专栏运营方所在地人民法院提起诉讼解决。
            </p>
            <p style="text-align: center; margin: 20px 0">
              <el-button size="mini" type="danger" @click="openRegister">
                同意并前往注册
              </el-button>
            </p>
          </div>
        </div>
        <div style="text-align: right; margin-top: 20px" v-if="!registerFlag">
          <el-button size="mini" type="primary" @click="openLogin">
            前往登录
          </el-button>
          <el-button size="mini" type="danger" @click="registerFlag = true">
            注册
          </el-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import img from '@/assets/images/logos.png'

export default {
  name: 'index',
  data() {
    return {
      registerFlag: false,
      showModal: false,
      img,
      activeIndex: 0,
      navList: [
        {
          name: '网站首页',
          url: '/index'
        },
        {
          name: '通知公告',
          url: '/newsList'
        },
        {
          name: '考试专题',
          url: '/examTypeList'
        },
        {
          name: '政策法规',
          url: '/special'
        },
        {
          name: '对外服务',
          url: '/intro'
        },
        {
          name: '常见问题',
          url: '/questionList'
        },
        {
          name: '警示案例',
          url: '/caseList'
        }
      ],
      keyword: ''
    }
  },
  methods: {
    openLogin() {
      window.open(
        'https://www.schrss.org.cn/ssologin/login?service=https%3A%2F%2Fersuserapi.jdpta.com%2Fyethan%2Flogin%2Fcas'
      )
    },
    openRegister() {
      this.registerFlag = false
      window.open(
        'https://www.schrss.org.cn/authfront/auth/userRegisterAction.do?service=https%3A%2F%2Fersuserapi.jdpta.com%2Fyethan%2Flogin%2Fcas'
      )
    },
    changeClass(obj, index) {
      window.location.href = obj.url
      this.activeIndex = index
    },

    /**
     * 跳转新闻页面并搜索
     */
    serchNews() {
      var keyword = this.keyword.replace(/\s*/g, '')
      if (keyword != '') {
        let routeUrl = this.$router.resolve({
          path: '/newsList',
          query: {
            keyword: keyword
          }
        })
        window.open(routeUrl.href, '_self')
      }
    }
  },
  created() {
    let url = window.location.href
    if (url.includes('examList')) {
      this.activeIndex = 0
      return
    }
    if (url.includes('examList')) {
      this.activeIndex = ''
      return
    }
    if (url.includes('caseList') || this.$route.query.type == 'jsal') {
      this.activeIndex = 6
      return
    }
    if (url.includes('newsList') || url.includes('newsDetail')) {
      this.activeIndex = 1
      return
    }
    if (url.includes('examTypeList') || url.includes('examPlan')) {
      this.activeIndex = 2
      return
    }
    if (url.includes('special')) {
      this.activeIndex = 3
      return
    }
    if (url.includes('intro')) {
      this.activeIndex = 4
      return
    }
    if (url.includes('questionList')) {
      this.activeIndex = 5
      return
    }
    // for (const key in this.navList) {
    //   if (url.includes(this.navList[key].url)) {
    //     this.activeIndex = key
    //     return
    //   }
    // }
  }
}
</script>
<style>
.modal-dialog {
  max-width: 760px !important; /* 设置模态框的最大宽度 */
}
p {
  margin-bottom: 0px;
  line-height: 25px;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
</style>
<style scoped>
.logo-class {
  height: 88px;
  display: inline-block;
  background-repeat: no-repeat;
}
.top_login {
  display: inline-block;
  float: right;
  margin-right: 10px;
  padding-top: 8px;
  color: #004652;
  font-size: 20px;
}
.top_login a {
  text-decoration: none;
  font-size: 14px;
  color: #32619d;
}
.navBar {
  width: 1200px;
  min-width: 1200px;
  height: 60px;
  margin: 0px auto;
  background: #328ad1;
  margin-top: 55px;
  text-align: left;
}
.navBar-ul a:hover {
  background-color: #3269a7;
}
.navBar-ul .active {
  background-color: #00448f;
}
</style>
